import React from "react";
import { Table, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import Helmet from "react-helmet";

class GoverningBody extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
          Screen Reader - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Screen Reader - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Screen Reader, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>

            </section>
          </div>
          <section className="section section-lg" style={{marginLeft:'10%' , marginRight: '10%'}}>
            
            {/* <Row>
              <Col xs={2}></Col>

              <Col xs={20}>

              
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Screen Reader</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={35} xs={35} className="mt-5 mb-5">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          
                          <th style={{ width: "560px" , textAlign:"center"}}>Screen Reader</th>
                          <th style={{ width: "580px" , textAlign:"center"}}>Website</th>
                          <th style={{ width: "180px" , textAlign:"center" }}>Free / Commercial</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{textAlign:"justify"}}>
                          Screen Access For All (SAFA)
                          <br/>

                          </td>
                          <td>
                          <a href="https://www.nvaccess.org/" target="_blank">https://www.nvaccess.org/ </a>
                          </td>
                          <td className="text_center"><b>Free</b></td>
                        </tr>
                        <tr>
                          <td style={{textAlign:"justify"}}>
                          Non Visual Desktop Access (NVDA)
                          <br/>

                          </td>
                          <td>
                          <a href="http://www.nvda-project.org/" target="_blank">http://www.nvda-project.org/</a>
                          </td>
                          <td className="text_center"><b>Free</b></td>
                        </tr>



                        <tr>
                          <td style={{textAlign:"justify"}}>
                          System Access To Go
                          <br/>

                          </td>
                          <td>
                          <a href="http://www.satogo.com/" target="_blank">http://www.satogo.com/</a>
                          </td>
                          <td className="text_center"><b>Free</b></td>
                        </tr>


                        <tr>
                          <td style={{textAlign:"justify"}}>
                         Thunder
                          <br/>

                          </td>
                          <td>
                          <a href="http://www.screenreader.net/index.php?pageid=2" target="_blank">http://www.screenreader.net/index.php?pageid=2</a>
                          </td>
                          <td className="text_center"><b>Free</b></td>
                        </tr>

                        <tr>
                          <td style={{textAlign:"justify"}}>
                         WebAnywhere
                          <br/>

                          </td>
                          <td>
                          <a href="http://webanywhere.cs.washington.edu/wa.php" target="_blank">http://webanywhere.cs.washington.edu/wa.php</a>
                          </td>
                          <td className="text_center"><b>Free</b></td>
                        </tr>

                        <tr>
                          <td style={{textAlign:"justify"}}>
                          Hal
                          <br/>

                          </td>
                          <td>
                          <a href="http://www.yourdolphin.co.uk/productdetail.asp?id=5" target="_blank">http://www.yourdolphin.co.uk/productdetail.asp?id=5</a>
                          </td>
                          <td className="text_center"><b>Commercial</b></td>
                        </tr>

                        <tr>
                          <td style={{textAlign:"justify"}}>
                          JAWS
                          <br/>

                          </td>
                          <td>
                          <a href="http://www.freedomscientific.com/jaws-hq.asp" target="_blank">http://www.freedomscientific.com/jaws-hq.asp</a>
                          </td>
                          <td className="text_center"><b>Commercial</b></td>
                        </tr>

                        <tr>
                          <td style={{textAlign:"justify"}}>
                          Supernova
                          <br/>

                          </td>
                          <td>
                          <a href="http://www.yourdolphin.co.uk/productdetail.asp?id=1" target="_blank">http://www.yourdolphin.co.uk/productdetail.asp?id=1</a>
                          </td>
                          <td className="text_center"><b>Commercial</b></td>
                        </tr>

                        <tr>
                          <td style={{textAlign:"justify"}}>
                          Window-Eyes
                          <br/>

                          </td>
                          <td>
                          <a href="http://www.gwmicro.com/Window-Eyes/" target="_blank">http://www.gwmicro.com/Window-Eyes/</a>
                          </td>
                          <td className="text_center"><b>Commercial</b></td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>









              <br/>
              </Col>
              <Col xs={2}></Col>
            </Row> */}




<Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Screen Reader</b>
                  </h2>
                </Col>
              </Row>
<Row>
      <Col lg={12} xs={12} className="mt-5 mb-5">
        <div className="table-responsive">
   
          <Table bordered className="text-center">
            <thead>
              <tr>
                <th style={{ minWidth: "250px" }}>Screen Reader</th>
                <th style={{ minWidth: "250px" }}>Website</th>
                <th style={{ minWidth: "150px" }}>Free / Commercial</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Screen Access For All (SAFA)</td>
                <td>
                  <a href="https://www.nvaccess.org/" target="_blank" rel="noopener noreferrer">
                    https://www.nvaccess.org/
                  </a>
                </td>
                <td><b>Free</b></td>
              </tr>
              <tr>
                <td>Non Visual Desktop Access (NVDA)</td>
                <td>
                  <a href="http://www.nvda-project.org/" target="_blank" rel="noopener noreferrer">
                    http://www.nvda-project.org/
                  </a>
                </td>
                <td><b>Free</b></td>
              </tr>
              <tr>
                <td>System Access To Go</td>
                <td>
                  <a href="http://www.satogo.com/" target="_blank" rel="noopener noreferrer">
                    http://www.satogo.com/
                  </a>
                </td>
                <td><b>Free</b></td>
              </tr>
              <tr>
                <td>Thunder</td>
                <td>
                  <a href="http://www.screenreader.net/index.php?pageid=2" target="_blank" rel="noopener noreferrer">
                    http://www.screenreader.net/index.php?pageid=2
                  </a>
                </td>
                <td><b>Free</b></td>
              </tr>
              <tr>
                <td>WebAnywhere</td>
                <td>
                  <a href="http://webanywhere.cs.washington.edu/wa.php" target="_blank" rel="noopener noreferrer">
                    http://webanywhere.cs.washington.edu/wa.php
                  </a>
                </td>
                <td><b>Free</b></td>
              </tr>
              <tr>
                <td>Hal</td>
                <td>
                  <a href="http://www.yourdolphin.co.uk/productdetail.asp?id=5" target="_blank" rel="noopener noreferrer">
                    http://www.yourdolphin.co.uk/productdetail.asp?id=5
                  </a>
                </td>
                <td><b>Commercial</b></td>
              </tr>
              <tr>
                <td>JAWS</td>
                <td>
                  <a href="http://www.freedomscientific.com/jaws-hq.asp" target="_blank" rel="noopener noreferrer">
                    http://www.freedomscientific.com/jaws-hq.asp
                  </a>
                </td>
                <td><b>Commercial</b></td>
              </tr>
              <tr>
                <td>Supernova</td>
                <td>
                  <a href="http://www.yourdolphin.co.uk/productdetail.asp?id=1" target="_blank" rel="noopener noreferrer">
                    http://www.yourdolphin.co.uk/productdetail.asp?id=1
                  </a>
                </td>
                <td><b>Commercial</b></td>
              </tr>
              <tr>
                <td>Window-Eyes</td>
                <td>
                  <a href="http://www.gwmicro.com/Window-Eyes/" target="_blank" rel="noopener noreferrer">
                    http://www.gwmicro.com/Window-Eyes/
                  </a>
                </td>
                <td><b>Commercial</b></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>



          </section>
        </main>
        <NewFooter />



      </>
    );
  }
}

export default GoverningBody;

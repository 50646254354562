import React, { useEffect, useState } from "react";
import Headroom from "react-headroom";
import { Link } from "react-router-dom";

import home_icon from "../assets/img/brand/home_icon.svg"

function TopBar() {

  const [isLanguageChanged, setIsLanguageChanged] = useState(false);
  const [fontSize, setFontSize] = useState(16); // Default font size

  // Font size change function
  const changeFontSize = (increment) => {
    setFontSize((prevSize) => {
      const newSize = increment ? prevSize + 1 : prevSize - 1;
      document.documentElement.style.fontSize = `${newSize}px`;


      return newSize;
    });
  };


  
  
  

    // Reset font size function
    const resetFontSize = () => {
      setFontSize(16); // Reset to default font size
      document.documentElement.style.fontSize = "16px"; // Apply to entire page
    };

    // For Google Translate script dynamically load
    useEffect(() => {
      const addGoogleTranslateScript = () => {
        const script = document.createElement("script");
        script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        script.async = true;
        document.body.appendChild(script);
      };
  
      //For initialize Google Translate 
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          { 
            pageLanguage: "en", 
            includedLanguages: "en,hi,te,ta,ur,bn,gu,kn,ml,mr,pa,or,as,ks,ne,si,sd,sa,doi,brx,mai,mni",
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            autoDisplay: false // For manually control of widget
          },
          "google_translate_element"
        );
      };
  
      addGoogleTranslateScript();
    }, []);





  return (
    <>
      <Headroom>
        <div className="container-fluid topbar text-light pl-5 pr-5">
          <div className="row ">
            <div className="col-lg-6 col-md-6 icons">
              <a href="/">
                <img src={home_icon} alt="..." style={{height:"20px", width:"20px", marginBottom: "2px"}}></img>
              </a>
              <a href="https://www.facebook.com/IIITKotaOffice/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook" />
              </a>
              <a href="https://twitter.com/IIITKotaOffice" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter" />
              </a>
              <a href="https://www.linkedin.com/school/iiitkotaoffice" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin" />
              </a>
              <a href="https://www.instagram.com/iiitkotaoffice/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram" />
              </a>
              <a href="https://www.youtube.com/channel/UCMvxTDPPnyuEPSGgjOv6HBQ" target="_blank" rel="noopener noreferrer">
                <i class="fab fa-youtube"></i>
              </a>
            </div>
            <div className="col-lg-6 col-md-6 text-right icons">
            <div id="google_translate_element" className="translate-box"></div>
            <span className="font-controls">

                    <button onClick={() => changeFontSize(false)} className="nosize" title="Decrease Font Size" style={{fontSize: '10px'}}>
                      {/* <FontAwesomeIcon icon={faMinus} />  */}
                      A-
                    </button>
                    <button onClick={() => changeFontSize(true)} className="nosize"  title="Increase Font Size" style={{fontSize: '15px'}}>
                      {/* <FontAwesomeIcon icon={faPlus} />  */}
                      A+
                    </button>
                    <button onClick={resetFontSize} title="Reset Font Size" className="nosize"  style={{fontSize: '15px'}}>
                    {/* <FontAwesomeIcon icon={faUndo} />  */}
                    A 
                  </button>
                    </span>
                    <Link to="/Screen-Reader" className="nosize">
              <i className="fas fa-mobile-alt" aria-hidden="true" ></i> &nbsp;
              Screen Reader
            </Link>
              <a href="https://goo.gl/maps/KfBmHcSVKmzt3hvj7" className="nosize">
                <i className="fa fa-map-marker" aria-hidden="true"></i> &nbsp;
                Location
              </a>
            </div>
          </div>
        </div>
      </Headroom>

      <style>
{`



.font-controls button {
font-family: 'Times New Roman', Times, serif;
border-radius:20px;
  border: none;
  
  cursor: pointer;
  margin-right:5px;
  margin-left:5px;
  background:none;
 
  color:white;

}

.font-controls button:hover {
  color:white;
}

`}
</style>
    </>
  );
}
export default TopBar;

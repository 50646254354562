import React from "react";
import "assets/css/style.css";
import {
  Card, CardBody, Container, Row, Col, Button, Collapse
} from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import nirfLogo from "assets/img/icons/common/nirf-logo.png";

class NirfData extends React.Component {
  state = {
    collapseOpen: false
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      collapseOpen: !prevState.collapseOpen
    }));
  };

  render() {
    return (
      <>
        <Helmet>
          <title>NIRF Data - IIIT Kota</title>
          <meta
            name="description"
            content="NIRF Data Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="NIRF Data IIIT Kota, IIIT Kota NIRF Data, NIRF Data IIIT, NIRF IIIT Kota,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            <section className="section section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section">
            <Container>
              <Row className="justify-content-center text-center mb-lg-5">
                <Col lg="8">
                  <h2>
                    <b>NIRF Data</b>
                  </h2>
                </Col>
              </Row>
            </Container>
            <section className="section pt-lg-0 ">
              <Container>
                <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid justify-content-center">
                      <Col lg="8">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">

                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div className="p-2">
                                <div className="">
                                  <img src={nirfLogo} alt="NIRF Logo" style={{ width: "150px", height: "150px", objectFit: "cover" }}
                                  />
                                </div>
                              </div>
                              <div className="p-2">

                                <h2 className="text-primary">
                                  NIRF Data 2025
                                </h2>
                                <h6>
                                  Institute ID: IR-O-U-0799
                                </h6>



                                
                                <div className="d-flex">
                                  <a
                                    href="https://files.iiitkota.ac.in/pdf/Nirf/NIRF%20report_Engineering%20category_INDIAN%20INSTITUTE%20OF%20INFORMATION%20TECHNOLOGY,%20KOTA20250130.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Button color="primary" className="mr-2">
                                      Engineering
                                    </Button>
                                  </a>

                                  <a
                                    href="https://files.iiitkota.ac.in/pdf/Nirf/NIRF%20report_Overall%20category_INDIAN%20INSTITUTE%20OF%20INFORMATION%20TECHNOLOGY,%20KOTA20250130.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Button color="primary" >
                                      Overall
                                    </Button>
                                  </a>
                                </div>
                              </div>
                            </div>

                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default NirfData;

import React from "react";
import "views/IndexSections/Sections.css";
import { Link } from "react-router-dom";

class NewFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: new Date().getFullYear(),
    };
  }

  componentDidMount() {
    // Check for year change once per day
    this.interval = setInterval(() => {
      const newYear = new Date().getFullYear();
      if (newYear !== this.state.currentYear) {
        this.setState({ currentYear: newYear });
      }
    }, 24 * 60 * 60 * 1000); // Check every 24 hours
  }

  componentWillUnmount() {
    clearInterval(this.interval); // Cleanup on unmount
  }

  render() {
    return (
      <>
        <div>
          <link
            href="https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.2/iconfont/material-icons.min.css"
            rel="stylesheet"
          />
          <link
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
            rel="stylesheet"
          />

          <footer>
            <div
              className="pt-5 text-light px-4 pb-5"
              style={{ overflowX: "hidden" }}
            >
              <div className="row">
                <div className="col-lg-9 px-lg-5 px-4">
                  <div className="university text-white">&nbsp;</div>
                  <div className="address text-white" >
                    {/* Location icon */}
                    <span className="material-icons notranslate text-white notranslate">
                      location_on
                    </span>
                    <a
                      href="https://goo.gl/maps/KfBmHcSVKmzt3hvj7"
                      className="text-white"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      IIIT Kota Permanent Campus, Kota, 325003
                    </a>

                    <span className="px-lg-4"></span>
                    <br className="d-lg-none space" />
                    {/* Email icon */}
                    <span className="material-icons notranslate text-light notranslate">email</span>{" "}
                    <a
                      href="mailto:office@iiitkota.ac.in"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white"
                    >
                      office@iiitkota.ac.in
                    </a>

                    <span className="px-lg-4"></span>
                    <br className="d-lg-none space" />
                  </div>

                  <div className="address text-white">
                    {/* Phone icon */}
                    <span className="material-icons notranslate text-light notranslate">
                      phone_in_talk
                    </span>{" "}
                    <a
                      href="tel:0744-2667000"
                      className="text-white"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      0744-2667000, 0744-2667010
                    </a>
                  </div>

                  <div>
                    <div
                      style={{ marginTop: "16px" }}
                      className="address text-white"
                    >
                      {/* Location icon */}
                      <span className="material-icons notranslate text-white">
                        location_on
                      </span>
                      <a
                        href="https://g.page/MNITJ?share"
                        className="text-white"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        MNIT Office, Jaipur, 302017
                      </a>
                      <span className="px-lg-4"></span>
                      <br className="d-lg-none space" />
                      {/* Phone icon */}
                      <span className="material-icons notranslate text-light">
                        phone_in_talk
                      </span>{" "}
                      <a
                        href="tel:0141-2715071"
                        className="text-white"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        0141-2715071
                      </a>
                    </div>
                  </div>

                  <ul className="links py-lg-4 pt-3 pb-4 border-bottom border-light">
                    <li>
                      <Link to="/holidays">
                        Holidays{" "}
                        <span className="material-icons notranslate">arrow_right_alt</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/iccw">
                        ICCW{" "}
                        <span className="material-icons notranslate">arrow_right_alt</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/recruitment">
                        Recruitment{" "}
                        <span className="material-icons notranslate">arrow_right_alt</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/tender-invitations">
                        Tender Invitations{" "}
                        <span className="material-icons notranslate">arrow_right_alt</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/antiragging">
                        Anti-Ragging Campaign{" "}
                        <span className="material-icons notranslate">arrow_right_alt</span>
                      </Link>
                    </li>
                  </ul>
                  <ul className="links py-lg-4 pb-3 border-bottom border-light">
                    <li>
                      <Link to="/webmasters">
                        Webmasters{" "}
                        <span className="material-icons notranslate">arrow_right_alt</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/it-infrastructure">
                        IT Infrastructure{" "}
                        <span className="material-icons notranslate">arrow_right_alt</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/rti">
                        RTI{" "}
                        <span className="material-icons notranslate">arrow_right_alt</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/sitemap">
                        Sitemap{" "}
                        <span className="material-icons notranslate">arrow_right_alt</span>
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://eldalab.in/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textTransform: "none" }}
                      >
                        e-LDA Lab{" "}
                        <span className="material-icons notranslate">arrow_right_alt</span>
                      </a>
                    </li>
                    <li>
                      <Link to="/rules-desciplines">
                        Rules and Discipline{" "}
                        <span className="material-icons notranslate">arrow_right_alt</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 border-left border-light aside px-4">
                  <img
                    alt="/"
                    style={{ width: "100px" }}
                    src={require("assets/img/brand/logo.png")}
                  />
                  <div className="lead mt-lg-3 mt-4 pr-4">
                    INDIAN INSTITUTE OF INFORMATION TECHNOLOGY KOTA
                  </div>
                  <ul className="links pt-3">
                    <li id="sponsor">
                      <a
                        href="https://goo.gl/maps/KfBmHcSVKmzt3hvj7"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit IIITKota
                      </a>
                      <span className="material-icons notranslate">arrow_right_alt</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
          <div className="px-4 text-light">
            <div className="row">
              <div className="col-md-6 px-lg-5 px-4 footer-copyright">
                &copy; {this.state.currentYear} IIIT Kota
              </div>
              <div className="col-md-6 icons text-right">
                <a href="https://www.facebook.com/IIITKotaOffice/">
                  <i className="fab fa-facebook" />
                </a>
                <a href="https://www.instagram.com/iiitkotaoffice/">
                  <i className="fab fa-instagram" />
                </a>
                <a href="https://www.linkedin.com/school/iiitkotaoffice">
                  <i className="fab fa-linkedin" />
                </a>
                <a href="mailto:office@iiitkota.ac.in">
                  <i className="fab fa-google-plus"></i>
                </a>
                <a href="https://twitter.com/IIITKotaOffice">
                  <i className="fab fa-twitter" />
                </a>
                <a href="https://www.youtube.com/channel/UCMvxTDPPnyuEPSGgjOv6HBQ">
                  <i className="fab fa-youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NewFooter;
